<template>
    <div class="pa-0 ma-0">

        <!--Parte visible-->
        <tr class="row">
            <td class="pl-4 td1"><div>{{ item.nombre }}</div></td>

            <td class="td2">
                <div class="tblOpciones">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="abrirModal(item.id)">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">autorenew</v-icon>
                        </v-btn>
                        </template>
                        <span>Renovar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="tarjetas.length > 0">
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                        </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>

        <!--Parte desplegable-->
        <template v-if="isOpen">
            <tr class="td-titles">
                <td>
                    <div>Fecha inicial</div>
                </td>
                <td>
                    <div>Fecha final</div>
                </td>
                <td>
                    <div>Estatus</div>
                </td>
                <td>
                    <div class="tblOpciones">
                        Opciones
                    </div>
                </td>
            </tr>

            <tr v-for="(tarjeta, index) in tarjetas" :key="tarjeta.id">
                <td>
                    <div>
                        {{ dateFormat(tarjeta.fecha_inicio) }}
                    </div>
                </td>
                <td>
                    <div>
                        {{ dateFormat(tarjeta.fecha_fin) }}
                    </div>
                </td>
                <td>
                    <div>
                        <p v-if="tarjeta.estatus_clave == 'estatus_vigente'" class="vigente mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                        <p v-if="tarjeta.estatus_clave == 'estatus_pendiente'" class="pendiente mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                        <p v-if="tarjeta.estatus_clave == 'estatus_por_vencer'" class="por-vencer mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                        <p v-if="tarjeta.estatus_clave == 'estatus_vencido'" class="vencido mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                        <p v-if="tarjeta.estatus_clave == 'estatus_cancelado'" class="vencido mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                        <p v-if="tarjeta.estatus_clave == 'estatus_renovado'" class="renovado mb-0 text-center" style="width: 175px;">{{ tarjeta.estatus_nombre }}</p>
                    </div>
                </td>
                <td style="padding-left:8px; padding-right:8px;">
                    <div class="tblOpciones">
                        <v-tooltip bottom v-if="tarjeta.url_archivo">
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                v-on="on" 
                                class="botonHover"
                                icon 
                                fab 
                                dark 
                                small 
                                @click="onVistaPrevia(tarjeta.url_archivo)">
                                    <v-icon class="iconoDelgadoBoton" v-text="'$visibility_outline'"></v-icon>
                                </v-btn>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>

                        <v-menu  buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon 
                                    fab
                                    dark
                                    small
                                    class="elevation-0"
                                >
                                    <template  v-for="(item,valor) in attrs" >
                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                    </template>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    v-if="tarjeta.estatus_clave == 'estatus_pendiente' || tarjeta.estatus_clave == 'estatus_vigente'"
                                    @click="editar(tarjeta)"
                                >
                                    <v-list-item-title>Editar</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="updateDocumento(tarjeta)"
                                    :disabled="disabledSubirArchivo(tarjeta, index)"
                                    :class="disabledSubirArchivo(tarjeta, index) ? 'deshabilitado' : ''"
                                >
                                    <v-list-item-title>Subir archivo</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="descargar(tarjeta.url_archivo)"
                                    :disabled="!tarjeta.url_archivo"
                                    :class="(!tarjeta.url_archivo) ? 'deshabilitado' : ''"
                                >
                                    <v-list-item-title>Descargar archivo</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="cancelar(tarjeta.id)"
                                    :disabled="tarjeta.estatus_clave == 'estatus_cancelado'"
                                    :class="(tarjeta.estatus_clave == 'estatus_cancelado') ? 'deshabilitado' : ''"
                                >
                                    <v-list-item-title>Cancelar tarjeta</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </td>
            </tr>
        </template>
    </div>
</template>

<script>

import Notify from '@/plugins/notify'

    export default {
        name:'expandable-item',
        props:{
            item: {
                type: Object,
                required: true,
                default: ''
            }
        },
        computed:{
            tarjetas(){
                return this.item.tarjetas
            }
        },
        data(){
            return{
                isOpen: false,
                dialogPdf: false,
                titulo_modal: '',
                procesoFinalizado: false,
                path: ''
            }
        },
        methods: {
            abrirModal(id){
                this.$emit("abrirModalCrear", id)
            },

            updateDocumento(data){
                this.$emit("abrirUpdateDocumento", data)
            },

            editar(data){
                this.$emit("abrirModalEditar", data)
            },

            cancelar(id){
                this.$emit("cancelarTarjeta", id)
            },

            descargar(url){
                this.$emit("descargarDocumento", url)
            },

            onVistaPrevia(url){
                this.$emit("onVistaPrevia", url)
            },

            /* cerrarModal(){
                this.dialogPdf = false
                this.titulo_modal = ''
                this.procesoFinalizado = false
            }, */

            dateFormat(fecha) {
                if(fecha == null){
                    return;
                }
                let fecha2 = fecha.split("-");
                
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
                
            },
            disabledSubirArchivo(tarjeta, index){
                if(index != 0){
                    if(tarjeta.url_archivo != null){
                        return true
                    }
                    else{
                        return false
                    }
                }
            },

        },
    }
</script>

<style scoped>
 .row{
    display: flex;
    width: 95%;
    align-items: center;
    margin-top: 0;
    margin-right: 0;
    margin-left: 38px;
 }

 .td1{
    flex-grow: 1;
    background: transparent;
 }

 .td2{
    width: 200px;
    padding-top: 0 !important;
    background: transparent;
 }

 table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        /* max-width: 15rem; */
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        /* max-width: 15rem; */
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
    
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
    }

    .td-titles td{
        padding-top:10px !important;
        padding-bottom:10px !important;
        background: #f6f6f6;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #fdfcfc;
    }

    tbody > div {
        vertical-align: middle !important;
    }

    .deshabilitado{
        background:#fcf6f6;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    .deshabilitado:hover{
        background:#f2f2f2;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    .deshabilitado span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }

    /* Colores estatus */
    .vigente{
        border: solid 0.5px #c5f7cd !important;
        color: #3CC13B;
        padding:11px 0px;
        border-radius: 8px;
        width: 80px;
    }

    .vencido{
        border: solid 0.5px #ffc9cc !important;
        color: #F03738;
        padding:11px 0px;
        border-radius: 8px;
        width: 100px;
    }

    .pendiente{
        border: solid 0.5px #ccd3e2 !important;
        color: #325687;
        padding:11px 0px;
        border-radius: 8px;
        width: 135px; 
    }

    .renovado{
        border: solid 0.5px #f7e6c3 !important;
        color: #E19117;
        padding:11px 0px;
        border-radius: 8px;
        width: 175px;
    }

    .por-vencer{
        border: solid 0.5px #828282 !important;
        color: #828282;
        padding:11px 0px;
        border-radius: 8px;
        width: 91px;
    }

</style>