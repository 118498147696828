import Vue from "vue";

export default {
    createTarjeta(parametros) {
        return Vue.axios
            .post("tarjetas-salud", parametros)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    updateTarjeta(parametros) {
        return Vue.axios
            .post("tarjetas-salud/update", parametros)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    deleteTarjeta(id) {
        return Vue.axios
            .delete("tarjetas-salud/" + id)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    cancelarTarjeta(parametros) {
        return Vue.axios
            .post("tarjetas-salud/cancela", parametros)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error));
    },
    getTarjeta(id) {
        return Vue.axios
            .get("tarjetas-salud" + id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    getTarjetas(parametros) {
        return Vue.axios
            .get("tarjetas-salud/find", { params: parametros })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    getEstatus(parametros) {
        return Vue.axios
            .get("estatus-tarjetas/find", { params: parametros })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
};
