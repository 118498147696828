var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('tr',{staticClass:"row"},[_c('td',{staticClass:"pl-4 td1"},[_c('div',[_vm._v(_vm._s(_vm.item.nombre))])]),_c('td',{staticClass:"td2"},[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal(_vm.item.id)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v("autorenew")])],1)]}}])},[_c('span',[_vm._v("Renovar")])]),(_vm.tarjetas.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.isOpen ? _vm.isOpen = false:_vm.isOpen = true}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(_vm._s(_vm.isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'))])],1)]}}],null,false,801905789)},[_c('span',[_vm._v("Desplegar")])]):_vm._e()],1)])]),(_vm.isOpen)?[_vm._m(0),_vm._l((_vm.tarjetas),function(tarjeta,index){return _c('tr',{key:tarjeta.id},[_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(tarjeta.fecha_inicio))+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(tarjeta.fecha_fin))+" ")])]),_c('td',[_c('div',[(tarjeta.estatus_clave == 'estatus_vigente')?_c('p',{staticClass:"vigente mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e(),(tarjeta.estatus_clave == 'estatus_pendiente')?_c('p',{staticClass:"pendiente mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e(),(tarjeta.estatus_clave == 'estatus_por_vencer')?_c('p',{staticClass:"por-vencer mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e(),(tarjeta.estatus_clave == 'estatus_vencido')?_c('p',{staticClass:"vencido mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e(),(tarjeta.estatus_clave == 'estatus_cancelado')?_c('p',{staticClass:"vencido mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e(),(tarjeta.estatus_clave == 'estatus_renovado')?_c('p',{staticClass:"renovado mb-0 text-center",staticStyle:{"width":"175px"}},[_vm._v(_vm._s(tarjeta.estatus_nombre))]):_vm._e()])]),_c('td',{staticStyle:{"padding-left":"8px","padding-right":"8px"}},[_c('div',{staticClass:"tblOpciones"},[(tarjeta.url_archivo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.onVistaPrevia(tarjeta.url_archivo)}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$visibility_outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]):_vm._e(),_c('v-menu',{staticClass:"elevation-0 d-inline",attrs:{"buttom":"","left":"","content-class":"mt-0 menuClassAcciones"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"icon":"","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._l((attrs),function(item,valor){return [(valor == 'aria-expanded' &&  item == 'false')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#828282"}},[_vm._v("more_vert")]):_vm._e(),(valor == 'aria-expanded' &&  item == 'true')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#2DB9D1"}},[_vm._v("more_vert")]):_vm._e()]})],2)]}}],null,true)},[_c('v-list',[(tarjeta.estatus_clave == 'estatus_pendiente' || tarjeta.estatus_clave == 'estatus_vigente')?_c('v-list-item',{on:{"click":function($event){return _vm.editar(tarjeta)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1):_vm._e(),_c('v-list-item',{class:_vm.disabledSubirArchivo(tarjeta, index) ? 'deshabilitado' : '',attrs:{"disabled":_vm.disabledSubirArchivo(tarjeta, index)},on:{"click":function($event){return _vm.updateDocumento(tarjeta)}}},[_c('v-list-item-title',[_vm._v("Subir archivo")])],1),_c('v-list-item',{class:(!tarjeta.url_archivo) ? 'deshabilitado' : '',attrs:{"disabled":!tarjeta.url_archivo},on:{"click":function($event){return _vm.descargar(tarjeta.url_archivo)}}},[_c('v-list-item-title',[_vm._v("Descargar archivo")])],1),_c('v-list-item',{class:(tarjeta.estatus_clave == 'estatus_cancelado') ? 'deshabilitado' : '',attrs:{"disabled":tarjeta.estatus_clave == 'estatus_cancelado'},on:{"click":function($event){return _vm.cancelar(tarjeta.id)}}},[_c('v-list-item-title',[_vm._v("Cancelar tarjeta")])],1)],1)],1)],1)])])})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"td-titles"},[_c('td',[_c('div',[_vm._v("Fecha inicial")])]),_c('td',[_c('div',[_vm._v("Fecha final")])]),_c('td',[_c('div',[_vm._v("Estatus")])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_vm._v(" Opciones ")])])])}]

export { render, staticRenderFns }