<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDescargar" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"  
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Tarjetas de Salud'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10,25,50,100]"
                        :showAdvancedFilters="true"
                        @loadModalData="loadModalData()"
                        @setFilters="setFilters"
                    >
                        <template slot="actionButtonsLeft">
                            <BtnAcciones
                                :btnNombre="'Acciones'"
                                :btnColor="'color-58C5D8'"
                            >
                                <template v-slot:opciones>
                                    <div 
                                        @click="abrirModalReporte()" 
                                        class="btnAccionesItem py-2"
                                    >
                                        <span>Descargar reporte</span>
                                    </div>
                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div @click="abrirModalLayout('descargar')" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="abrirModalLayout('subir')" class="btnAccionesItem py-2">
                                            <span>Importar layout para agregar empleados</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="layout()" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="modalLayout()" class="btnAccionesItem py-2">
                                            <span>Importar layout para agregar empleados</span><br />
                                        </div>
                                    </template>
                                </template>
                            </BtnAcciones>
                            <div class="botonAgregar mr-3">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="openDialog('principal')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>

                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="advancedSearch.cliente"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" :sm="(rol == 'admin') ? '12' : 6" :md="(rol == 'admin') ? '12' : 6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="advancedSearch.empresa"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" :sm="(rol == 'admin-empresa') ? '12' : 6" :md="(rol == 'admin-empresa') ? '12' : 6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="advancedSearch.sucursal"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="advancedSearch.departamento"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empleados"
                                        item-text="nombre_completo"
                                        item-value="id"
                                        label="Empleado"
                                        persistent-hint
                                        v-model="advancedSearch.empleado"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="estatusArr"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estatus"
                                    persistent-hint
                                    v-model="advancedSearch.estatus"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoFecha">
                                    <span>Rango de inicio</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha inicio"
                                    :valorDefecto="advancedSearch.fecha_inicio_inferior"
                                    v-model="advancedSearch.fecha_inicio_inferior"
                                    placeholder="dd/mm/aaaa"
                                    ref="fecha_inicio_inferior"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha fin"
                                    :valorDefecto="advancedSearch.fecha_inicio_superior"
                                    v-model="advancedSearch.fecha_inicio_superior"
                                    placeholder="dd/mm/aaaa"
                                    ref="fecha_inicio_superior"
                                />
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoFecha">
                                    <span>Rango de fin</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha inicio"
                                    :valorDefecto="advancedSearch.fecha_fin_inferior"
                                    v-model="advancedSearch.fecha_fin_inferior"
                                    placeholder="dd/mm/aaaa"
                                    ref="fecha_fin_inferior"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha fin"
                                    :valorDefecto="advancedSearch.fecha_fin_superior"
                                    v-model="advancedSearch.fecha_fin_superior"
                                    placeholder="dd/mm/aaaa"
                                    ref="fecha_fin_superior"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{data}">
                            <tr :key="empleado.id" v-for="empleado in data">
                                <expandable-item 
                                    :item="empleado"
                                    @abrirModalCrear="openDialogSecundario"
                                    @abrirUpdateDocumento="openUpdate" 
                                    @descargarDocumento="descargarDocumento"
                                    @abrirModalEditar="openModalEditar"
                                    @cancelarTarjeta="cancelarTarjeta"
                                    @onVistaPrevia="vistaPrevia"
                                    />
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <perfect-scrollbar style="background: #fff;">
                    <v-card elevation="0" class="borde-card">
                        <ValidationObserver ref="form" v-slot="{invalid}"> 
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="tipo == 'principal'">
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> 
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" :sm="(rol == 'admin') ? 12 : 6" :md="(rol == 'admin') ? 12 : 6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> 
                                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Sucursal"
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="sucursal_value"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> 
                                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Departamento"
                                                    :items="departamentos"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="departamento_value"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> 
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empleado"
                                                        :items="empleados"
                                                        item-text="nombre_completo"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empleado_value"
                                                        class="pa-0 ma-0"
                                                        required
                                                        :error-messages="errors"
                                                    >
                                                    </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col> 
                                        </template>
                                        <template v-if="tipo != 'update-documento' || tipo == 'editar'">
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <date-picker
                                                        outlined
                                                        rules="required"
                                                        v-model="fecha_inicio"
                                                        :valorDefecto="fecha_inicio"
                                                        label="Fecha inicial"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaInicio"
                                                    />
                                                </v-col> 
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <date-picker
                                                        outlined
                                                        rules="required"
                                                        v-model="fecha_fin"
                                                        :valorDefecto="fecha_fin"
                                                        label="Fecha final"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaFin"
                                                    />
                                                </v-col> 
                                        </template>
                                        <template v-if="tipo != 'editar'">
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </v-col>
                                        </template>
                                </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>


            <v-dialog v-model="dialogReporte" persistent max-width="600px" class="borde-card" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <perfect-scrollbar style="background: #fff;">
                    <v-card elevation="0" class="borde-card">
                        <ValidationObserver ref="formReporte" v-slot="{invalid}"> 
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col> 
                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" :sm="(rol == 'admin') ? 12 : 6" :md="(rol == 'admin') ? 12 : 6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    class="pa-0 ma-0"
                                                    required
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col> 
                                        <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Sucursal"
                                                :items="sucursales"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="sucursal_value"
                                                class="pa-0 ma-0"
                                            >
                                            </v-autocomplete>
                                        </v-col> 
                                        <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Departamento"
                                                :items="departamentos"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="departamento_value"
                                                class="pa-0 ma-0"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <!--
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empleado"
                                                    :items="empleados"
                                                    item-text="nombre_completo"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empleado_value"
                                                    class="pa-0 ma-0"
                                                >
                                                </v-autocomplete>
                                            </v-col> 
                                        --> 
                                        <v-col xs="12" sm="12" md="12">
                                            <div class="RangoFecha">
                                                <span>Rango de inicio</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha inicio"
                                                :valorDefecto="fecha_inicio_inferior"
                                                v-model="fecha_inicio_inferior"
                                                placeholder="dd/mm/aaaa"
                                                ref="fecha_inicio_inferior"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha fin"
                                                :valorDefecto="fecha_inicio_superior"
                                                v-model="fecha_inicio_superior"
                                                placeholder="dd/mm/aaaa"
                                                ref="fecha_inicio_superior"
                                            />
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12">
                                            <div class="RangoFecha">
                                                <span>Rango de fin</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha inicio"
                                                :valorDefecto="fecha_fin_inferior"
                                                v-model="fecha_fin_inferior"
                                                placeholder="dd/mm/aaaa"
                                                ref="fecha_fin_inferior"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha fin"
                                                :valorDefecto="fecha_fin_superior"
                                                v-model="fecha_fin_superior"
                                                placeholder="dd/mm/aaaa"
                                                ref="fecha_fin_superior"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-select
                                                outlined
                                                :items="itemsTipoReporte"
                                                item-text="descripcion"
                                                item-value="valor"
                                                label="Tipo de reporte"
                                                persistent-hint
                                                v-model="tipoReporte"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isLoading"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="descargarReporte()" 
                                    :disabled="invalid" 
                                    :loading="isLoading"
                                >
                                    Descargar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>

            <v-dialog v-model="dialogLayout" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form1" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingLayout">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivoLayout.archivo"
                                                accept=".xlsx"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                            <v-btn class="btnGuardar"  @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogLayoutTarjetas" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayoutEmpleados"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form2" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingLayout">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="accionLayout === 'subir'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivoLayout.archivo"
                                                accept=".xlsx"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                            <v-btn class="btnGuardar" v-if="accionLayout === 'subir'" @click="guardarLayout2()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            <v-btn class="btnGuardar" v-if="accionLayout === 'descargar'" @click="layout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <!--Vista previa-->
            <v-dialog v-model="dialogPdf" persistent max-width="700px">
                <v-card>
                    <div class="d-flex justify-end">
                        <v-btn
                            @click="cerrarModal()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar mr-3 mt-3"
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn> 
                    </div>
                    <v-card-title class="pt-0">
                        <span class="titleModal ml-2" v-text="titulo_modal"></span>
                    </v-card-title>
                    <v-card-text class="px-0 pb-0">
                        <v-container grid-list-md>
                            <v-row class="ma-0">
                                <template v-if="!procesoFinalizado">
                                    <v-col cols="12" xs="12" sm="12" class="align-center mb-10" align="center" justify="center" >
                                        <v-col cols="12" xs="12" sm="12" md="12" class="align-center mb-10" align="center" justify="center">
                                            <h3 class="tituloCodigo">Procesando</h3>
                                            <beat-loader
                                                :loading="true"
                                                :color="'#004BAF'"
                                                :margin="'2px'"
                                                :radius="'100px'"
                                            ></beat-loader>
                                        </v-col>  
                                    </v-col>
                                </template>
                                <template>
                                    <v-col v-show="procesoFinalizado"  cols="12"  xs="12" sm="12" md="12">
                                        <iframe src="" id="myIframe"  width='100%' height='460px' allowfullscreen webkitallowfullscreen></iframe>  
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                            <v-btn
                                @click="cerrarModalErrores()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right"
                                absolute
                                top
                                right
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Errores</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoadingError">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-errores">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px; border-top-right-radius: 20px;" class="errorth">Error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="erroresModal.length == 0" >
                                                <td class="emptyTableErrores" colspan="2">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(error, index) in erroresModal">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="errortd"> <div class="textoTablaError03">{{error}}</div> </td>
                                                    </tr>
                                                    <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Datatable from '@/components/datatable/Datatable'
import VuetifyDatePicker from '@/components/VuetifyDatePicker'
import ExpandableItemSalud from '@/components/ExpandableItemSalud.vue'
import BtnAcciones from '@/components/BtnAcciones.vue';
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';
import apiSucursales from '@/api/sucursales';
import apiDepartamentos from '@/api/departamentos'
import apiEmpleados from '@/api/empleados'
import apiTarjetas from '@/api/tarjetas_salud'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

import Notify from '@/plugins/notify'

    export default {
        components: {
            BeatLoader,
            'data-table': Datatable,
            'date-picker': VuetifyDatePicker,
            'expandable-item': ExpandableItemSalud,
            BtnAcciones,
        },
        
        data(){
            return{
                datosLogin: null,
                rol: null,
                url: 'tarjetas-salud/find',
                columns: [
                    {
                        label: 'Empleado',
                        name: 'nombre',
                        filterable: false,
                        align:'center'
                    }
                ],
                filters:{
                    cliente_id: null,
                    empresa_id: null,
                    sucursal_id: null,
                    departamento_id: null,
                    estatus_id: null,
                    fecha_inicio_inferior: null,
                    fecha_inicio_superior: null,
                    fecha_fin_inferior: null,
                    fecha_fin_superior: null,
                    empleado_id: null,
                    activo: true,
                    paginate: true,
                    order: 'desc'
                },
                advancedSearch: {
                    cliente: null,
                    empresa: null,
                    sucursal: null,
                    departamento: null,
                    empleado: null,
                    estatus: null,
                    fecha_inicio_inferior: null,
                    fecha_inicio_superior: null,
                    fecha_fin_inferior: null,
                    fecha_fin_superior: null,
                    
                },
                archivo: null,
                cliente_value: null,
                empresa_value: null,
                sucursal_value: null,
                departamento_value: null,
                empleado_value: null,
                fecha_inicio: null,
                fecha_fin: null,
                dialog: false,
                isLoading: false,
                empleados: [],
                clientes: [],
                empresas: [],
                sucursales: [],
                departamentos: [],
                titulo_modal: '',
                isSaving: false,
                tipo: '',
                tarjeta_id: null,
                estatusArr: [],
                dialogReporte: false,
                dialogPdf: false,
                procesoFinalizado: false,

                fecha_inicio_inferior: null,
                fecha_inicio_superior: null,
                fecha_fin_inferior: null,
                fecha_fin_superior: null,
                tipoReporte: 'consolidado',
                itemsTipoReporte : [
                    {
                        descripcion : "Reporte historico",
                        valor       : "historico"
                    },
                    {
                        descripcion : "Reporte de estatus actual",
                        valor       : "consolidado"
                    },
                ],

                dialogLayout: false,
                dialogLayoutTarjetas: false,
                isLoadingLayout: false,
                accionLayout: 'descargar',
                archivoLayout       : {
                    archivo         : null,
                    cliente_id      : null,
                    empresa_id      : null,
                    sucursal_id     : null,
                    tipo            : 'xlsx'
                },
                overlayDescargar: false,
                frase: 'Descargando...',
                dialogErrores: false,
                isLoadingError: false,
                erroresModal: [],
            }
        },
        watch:{
            cliente_value:function(newVal){
                if(newVal != null){
                    this.getEmpresas(newVal)
                }
            },
            empresa_value:function(newVal){
                if(newVal != null){
                    this.getSucursales(newVal)
                }
            },
            sucursal_value:function(newVal){
                if(newVal != null){
                    this.getDepartamentos(newVal)
                }
            },
            departamento_value:function(newVal){
                if(newVal != null){
                    this.getEmpleados(newVal)
                }
            },

            'advancedSearch.cliente':function(newVal) {
                if(newVal != null){
                    this.getEmpresas(newVal)
                }
            },
            'advancedSearch.empresa':function(newVal) {
                if(newVal != null){
                    this.getSucursales(newVal)
                }
            },
            'advancedSearch.sucursal':function(newVal) {
                if(newVal != null){
                    this.getDepartamentos(newVal)
                }
            },
            'advancedSearch.departamento':function(newVal) {
                if(newVal != null){
                    this.getEmpleados(newVal)
                }
            },
        },
        methods: {
            listar(){
                this.datosLogin = this.$session.get("usuario")
                this.rol = this.datosLogin.rol.name

                if(this.rol == 'admin'){
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id
                }
                else if(this.rol == 'admin-empresa'){
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id
                    this.filters.empresa_id = this.empresa_value = this.datosLogin.empresa_id
                }
                else if(this.rol == 'admin-sucursal'){
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id
                    this.filters.empresa_id = this.empresa_value = this.datosLogin.empresa_id
                    this.filters.sucursal_id = this.sucursal_value = this.datosLogin.sucursal_id
                }
                else{
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id
                    this.filters.empresa_id = this.empresa_value = this.datosLogin.empresa_id
                    this.filters.sucursal_id = this.sucursal_value = this.datosLogin.sucursal_id
                    this.filters.departamento_id = this.departamento_value = this.datosLogin.departamento_id
                }
            },
            async openDialog(){
                await this.resetValues()
                this.titulo_modal = 'Nueva Tarjeta de Salud'
                this.dialog = true
                this.tipo = 'principal'
                //console.log(this.empleado_value);


                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.getEmpresas(this.cliente_value)
                }
                else if(this.rol == 'admin-empresa'){
                    this.getSucursales(this.empresa_value)
                }
                else if(this.rol == 'admin-sucursal'){
                    this.getDepartamentos(this.sucursal_value)
                }
                else{
                    this.getEmpleados(this.departamento_value)
                }
                
            },
            openDialogSecundario(id=null){
                //console.log('probando emit: ', id);
                this.titulo_modal = 'Nueva Tarjeta de Salud'
                this.tipo = 'secundario'
                this.dialog = true
                this.empleado_value = id
                //console.log(this.empleado_value);
            },
            openUpdate(data){
                //console.log(data);
                this.tipo = 'update-documento'
                this.titulo_modal = 'Subir archivo'
                this.dialog = true
                this.empleado_value = data.empleado_id
                this.fecha_inicio = data.fecha_inicio
                this.fecha_fin = data.fecha_fin
                this.tarjeta_id = data.id

            },

            openModalEditar(data){
                this.tipo = 'editar'
                this.titulo_modal = 'Editar Tarjeta'
                this.dialog = true
                this.empleado_value = data.empleado_id
                this.fecha_inicio = data.fecha_inicio
                this.fecha_fin = data.fecha_fin
                this.tarjeta_id = data.id
            },

            async loadModalData(){
                await this.resetValues()

                this.getEstatus()

                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.advancedSearch.cliente = this.cliente_value
                }
                else if(this.rol == 'admin-empresa'){
                    this.advancedSearch.cliente = this.cliente_value
                    this.advancedSearch.empresa = this.empresa_value
                }
                else if(this.rol == 'admin-sucursal'){
                    this.advancedSearch.cliente = this.cliente_value
                    this.advancedSearch.empresa = this.empresa_value
                    this.advancedSearch.sucursal = this.sucursal_value
                }
                else{
                    this.advancedSearch.cliente = this.cliente_value
                    this.advancedSearch.empresa = this.empresa_value
                    this.advancedSearch.sucursal = this.sucursal_value
                    this.advancedSearch.departamento = this.departamento_value
                }

            },

            setFilters(){

                this.filters.cliente_id = this.advancedSearch.cliente
                this.filters.empresa_id = this.advancedSearch.empresa
                this.filters.sucursal_id = this.advancedSearch.sucursal
                this.filters.departamento_id = this.advancedSearch.departamento
                this.filters.empleado_id = this.advancedSearch.empleado
                this.filters.estatus_id = this.advancedSearch.estatus
                this.filters.fecha_inicio_inferior = this.advancedSearch.fecha_inicio_inferior
                this.filters.fecha_inicio_superior = this.advancedSearch.fecha_inicio_superior
                this.filters.fecha_fin_inferior = this.advancedSearch.fecha_fin_inferior
                this.filters.fecha_fin_superior = this.advancedSearch.fecha_fin_superior

                this.$refs.tabla.getData()

            },

            guardar(){
                let self = this
                this.$refs.form.validate().then(success => {
                    this.isSaving = true
                    if(!success){
                        this.isSaving = false
                        return
                    }


                    var f_inicio = new Date(this.fecha_inicio);
                    var f_final = new Date(this.fecha_fin);

                    if (f_inicio > f_final) {
                        Notify.ErrorToast("Rango de fechas incorrectas.");
                        this.isSaving = false;
                        return;
                    } 

                    let formData = new FormData()

                    formData.append("empleado_id", this.empleado_value)
                    formData.append("fecha_inicio", this.fecha_inicio)
                    formData.append("fecha_fin", this.fecha_fin)

                    if(this.archivo != null){
                        formData.append("archivo", this.archivo)
                    }

                    if(this.tipo == 'update-documento' || this.tipo == 'editar'){
                        formData.append("id", this.tarjeta_id)
                        apiTarjetas.updateTarjeta(formData).then(resp => {
                            Notify.Success("Operación exitosa", "La tarjeta se actualizó correctamente")
                            self.$refs.tabla.getData()
                            this.cerrarModal()
                        }).catch(err => {
                            this.isSaving = false
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;
    
                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
    
                                Notify.ErrorToast(leyenda);
                            }
                        })
                    }
                    else{
                        apiTarjetas.createTarjeta(formData).then(resp =>{
                            Notify.Success("Operación exitosa", "La tarjeta se guardó correctamente")
                            self.$refs.tabla.getData()
                            this.cerrarModal()
                        }).catch(err => {
                            this.isSaving = false
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;
    
                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
    
                                Notify.ErrorToast(leyenda);
                            }
                        })    
                    }
                })
            },

            descargarDocumento(url){
                const FileDownload = require("js-file-download");
                var axios = require('axios');
                let path = "download";
                let parametros = { url: url};
                var nombre_archivo = url.split("/");
                nombre_archivo = nombre_archivo[3]
               // console.log(nombre_archivo);
                try {
                    axios({
                        method: "post",
                        url: path,
                        responseType: "blob",
                        data: parametros,
                    }).then(resp => {
                        if(resp.status == 200){
                            FileDownload(resp.data,nombre_archivo)
                        }
                    }
                    ).catch((err) => {
                        Notify.ErrorToast('No se puedo descargar el archivo.');
                        console.log(err);
                    });
                } catch (error) {
                    Notify.ErrorToast('No se puedo descargar el archivo.');
                    console.log(error);
                }
            },

            cancelarTarjeta(id){

                Notify. Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas cancelar la tarjeta actual?",
                    "Cancelar tarjeta",
                    ()=>{
                        apiTarjetas.cancelarTarjeta({id}).then(resp => {
                            Notify.Success("Operación exitosa", "La tarjeta se canceló correctamente")
                            this.$refs.tabla.getData()
                        }).catch(err => {
                        console.log('Ocurrió un error: ', err);
                        })
                })
            },

            vistaPrevia(url){
                this.titulo_modal = 'Vista previa'
                this.dialogPdf = true
                let path = "download"

                const axios = require("axios")

                let parametros = {url: url}

                try {
                    axios({
                        method: 'post',
                        url: path,
                        responseType: "blob",
                        data: parametros,
                    }).then((response) => {
                        //console.log(response.data);
                        var fileURL = URL.createObjectURL(response.data);
                        this.path = fileURL
                        //console.log(fileURL);
                        $('#myIframe').attr('src', fileURL); 
                        $('iframe').on("load", function () {
                            $(this).contents().find('img').css({width: '100%'});
                        }); 
                        this.procesoFinalizado = true
                    }).catch(err => {
                        Notify.ErrorToast('No se puede mostrar el archivo.');
                        console.log(err);
                    })
                } catch (error) {
                    console.log(error);
                }
            },

            abrirModalReporte(){
                this.titulo_modal = 'Descargar reporte'

                this.dialogReporte = true

                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.getEmpresas(this.cliente_value)
                }
                else if(this.rol == 'admin-empresa'){
                    this.getSucursales(this.empresa_value)
                }
                else if(this.rol == 'admin-sucursal'){
                    this.getDepartamentos(this.sucursal_value)
                }
                else{
                    this.getEmpleados(this.departamento_value)
                }

            },

            descargarReporte(){

                let url = "tarjetas-salud/reporte-tarjetas";
                this.isLoading = true
                let today = new Date();
                const FileDownload = require("js-file-download");
                const axios = require("axios");
                let self = this
                let parametros = {
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    sucursal_id: this.sucursal_value,
                    departamento_id: this.departamento_value,
                    //empleado_id: this.empleado_value,
                    fecha_inicio_inferior: this.fecha_inicio_inferior,
                    fecha_inicio_superior: this.fecha_inicio_superior,
                    fecha_fin_inferior: this.fecha_fin_inferior,
                    fecha_fin_superior: this.fecha_fin_superior,
                    tipo_reporte: this.tipoReporte,
                };

                var nombre =
                    "Reporte_tarjetas_salud" +
                    "_"+
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    ".xlsx";

                try {
                    axios({
                        method: "get",
                        url: url,
                        responseType: "blob",
                        params: parametros
                    })
                        .then((response) => {
                            
                            if (response.status == 200){
                                FileDownload(response.data, nombre);
                                self.isLoading = false  
                                self.cerrarModal()
                            } 
                            else
                                Notify.ErrorToast(
                                    "Algo ha salido mal, intenta de nuevo o consulta a soporte técnico."
                                );
                        })
                        .catch(async (err) => {
                            this.isLoading = false;
                            Notify.ErrorToast(
                                    "Ocurrió un error al descargar el reporte"
                                );
                            console.log(err);
                        });
                } catch (error) {
                    this.isLoading = false
                }
            },

            cerrarModal(){
                this.dialog = false
                this.dialogReporte = false
                this.dialogPdf = false
                this.dialogLayout = false
                this.dialogLayoutTarjetas = false
                this.isLoadingLayout = false
                this.resetValues()
            },

            async resetValues(){
                this.isSaving = false
                this.archivo = null
                if(this.rol == 'root'){
                    this.cliente_value = null
                    this.empresa_value = null
                    this.sucursal_value = null
                    this.departamento_value = null
                    this.clientes = []
                    this.empresas = []
                    this.sucursales = []
                    this.departamentos = []
                }
                else if(this.rol == 'admin'){
                    this.empresa_value = null
                    this.sucursal_value = null
                    this.departamento_value = null
                    this.empresas = []
                    this.sucursales = []
                    this.departamentos = []
                }
                else if(this.rol == 'admin-empresa'){
                    this.sucursal_value = null
                    this.departamento_value = null
                    this.sucursales = []
                    this.departamentos = []
                }
                this.empleado_value = null
                this.fecha_inicio = null
                this.fecha_fin = null
                this.fecha_inicio_inferior = null
                this.fecha_inicio_superior = null
                this.fecha_fin_inferior = null
                this.fecha_fin_superior = null
                this.tipo = ''
                this.tarjeta_id = null
                this.titulo_modal = ''
                this.procesoFinalizado = false
                this.tipoReporte = 'consolidado'

                this.empleados = []
                
                this.advancedSearch = {
                    cliente: null,
                    empresa: null,
                    sucursal: null,
                    departamento: null,
                    empleado: null,
                    estatus: null,
                    fecha_inicio_inferior: null,
                    fecha_inicio_superior: null,
                    fecha_fin_inferior: null,
                    fecha_fin_superior: null,
                }

                this.accionLayout = 'descargar'
                this.archivoLayout  = {
                    archivo         : null,
                    cliente_id      : null,
                    empresa_id      : null,
                    sucursal_id     : null,
                    tipo            : 'xlsx'
                }

                this.$nextTick(()=>{
                    if(this.$refs.form){
                        this.$refs.form.reset()
                    }
                    if(this.$refs.form1){
                        this.$refs.form1.reset()
                    }
                    if(this.$refs.form2){
                        this.$refs.form2.reset()
                    }
                    if(this.$refs.formReporte){
                        this.$refs.formReporte.reset()
                    }
                })

            },
            
            getClientes(){

                let params = {
                    activo: true,
                    paginate: false
                }

                apiClientes.getClientes(params).then(resp => {
                    this.clientes = resp.data
                }).catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los clientes")
                })

            },

            getEmpresas(id){
                let params = {
                    activo: true,
                    paginate: false,
                    cliente_id: id
                }

                apiEmpresas.getEmpresas(params).then(resp =>{
                    this.empresas = resp.data
                }).catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas")
                })
            },

            getSucursales(id){
                let params = {
                    activo: true,
                    paginate: false,
                    empresa_id: id
                }

                apiSucursales.getSucursales(params).then(resp =>{
                    this.sucursales = resp.data
                }).catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales")
                })
            },

            getDepartamentos(id){
                let params = {
                    activo: true,
                    paginate: false,
                    sucursal_id: id
                }

                apiDepartamentos.getDepartamentos(params).then(resp =>{
                    this.departamentos = resp.data
                }).catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los departamentos")
                })
            },

            getEmpleados(id){
                let params = {
                    activo: true,
                    paginate: false,
                    departamento_id: id,
                    rol_name: this.rol,
                    usuario_id: this.datosLogin.id
                }

                apiEmpleados.getEmpleados(params).then(resp => {
                    this.empleados = resp.data
                }).catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los empleados")
                })
            },
            getEstatus(){
                let params = {
                    activo: true,
                    paginate: false,
                    order_by: 'id',
                    order: 'asc'
                }

                apiTarjetas.getEstatus(params).then(resp => {
                    this.estatusArr = resp.data
                }).catch(err => {
                    console.log('Ocurrió un error', err);
                })
            },
            open(){
                document.getElementById("fileDocument").click();
            },

            abrirModalLayout(accion){
                this.isLoadingLayout = true;
                this.accionLayout = accion;
                this.dialogLayoutTarjetas = true;
                if(accion === 'descargar' ){
                    this.tituloModal  = 'Descargar layout para agregar empleados';
                }
                else if(accion === 'subir'){
                    this.tituloModal  = 'Subir layout para agregar empleados';
                }
                else if (accion === 'exportar'){
                    this.tituloModal  = 'Exportar layout de empleados';
                }


                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.archivoLayout.cliente_id = this.datosLogin.cliente_id;
                    this.getEmpresas(this.cliente_value)
                }
                else if(this.rol == 'admin-empresa'){
                    this.archivoLayout.cliente_id = this.datosLogin.cliente_id;
                    this.archivoLayout.empresa_id = this.datosLogin.empresa_id;
                    this.getSucursales(this.empresa_value)
                }
                else if(this.rol == 'admin-sucursal'){
                    this.getDepartamentos(this.sucursal_value)
                }
                else{
                    this.getEmpleados(this.departamento_value)
                }
                
                this.isLoadingLayout = false;
            },
            modalLayout() {
                this.tituloModal = "Subir layout para agregar empleados";
                this.archivoLayout.cliente_id = this.datosLogin.cliente_id;
                this.archivoLayout.empresa_id = this.datosLogin.empresa_id;
                if (this.rol == "admin-sucursal") {
                    this.archivoLayout.sucursal_id = this.datosLogin.sucursal_id;
                }

                setTimeout(function() {
                    document.getElementById("btnModalLayout").click();
                }, 300);
            },
            async layout() {
                let self = this;
                let url = "tarjetas-salud/export";
                const FileDownload = require("js-file-download");
                var today = new Date();
                let nombre;
                if (this.rol == "admin" || this.rol == "root") {
                    this.isSaving = true;
                    nombre =
                        "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        rol_name: "admin-empresa",
                        cliente_id: this.cliente_value,
                        empresa_id: this.empresa_value,
                    };
                }
                if (this.rol == "admin-empresa") {
                    this.overlayDescargar = true;
                    nombre =
                        "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        cliente_id: this.datosLogin.cliente_id,
                        empresa_id: this.datosLogin.empresa_id,
                    };
                }
                if (this.rol == "admin-sucursal") {
                    this.overlayDescargar = true;
                    nombre =
                    "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        cliente_id: this.datosLogin.cliente_id,
                        empresa_id: this.datosLogin.empresa_id,
                        sucursal_id: this.datosLogin.sucursal_id,
                    };
                }
                try {
                    await Vue.axios({
                        method: "GET",
                        responseType: "blob",
                        url: url,
                        params: object1
                    }).then((response, status, xhr) => {
                        FileDownload(response.data, nombre);
                        this.cerrarModal();
                        this.overlayDescargar = false;
                    })
                    .catch(err => {
                        console.log(err)
                        this.overlayDescargar = false;
                        this.isSaving = false;
                    });
                } catch (e) {
                    this.overlayDescargar = false;
                    this.isSaving = false;
                    console.log(e);
                }
            },
            guardarLayout() {
                let self = this;
                this.$refs.form1.validate().then(success => {
                    this.isSaving = true;
                    if(!success) {
                        this.isSaving = false;
                        return;
                    }
                    if(this.rol == "root" || this.rol == "admin"){
                        this.archivoLayout.cliente_id = this.cliente_value;
                        this.archivoLayout.empresa_id = this.empresa_value;
                        this.archivoLayout.rol_name   = "admin-empresa";
                    }
                    let formData = new FormData();
                    formData.append("archivo", this.archivoLayout.archivo);
                    formData.append("cliente_id", this.archivoLayout.cliente_id);
                    formData.append("empresa_id", this.archivoLayout.empresa_id);
                    formData.append("tipo", this.archivoLayout.tipo);

                    if (this.rol == "admin-sucursal") {
                        formData.append("sucursal_id", this.archivoLayout.sucursal_id);
                    }

                    formData.append("usuario_id", this.datosLogin.id);
                    
                    Vue.axios({
                        method: "post",
                        url: "tarjetas-salud/import",
                        data: formData,
                        config: { headers: { "Content-Type": "multipart/form-data" } }
                    })
                    .then((response) => {
                        if (response.status == 200) {
                            Notify.Success("Operación exitosa", "Se ha realizado la operación correctamente.");
                        }
                        else if(response.status == 202){
                            this.dialogErrores = true;
                            this.erroresModal  = response.data.errores;
                        }

                        this.cerrarModal();
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        //Notify.ErrorToast("Error al subir archivo.");
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                });
            },
            guardarLayout2() {
                let self = this;
                this.$refs.form2.validate().then(success => {
                    this.isSaving = true;
                    if(!success) {
                        this.isSaving = false;
                        return;
                    }
                    if(this.rol == "root" || this.rol == "admin"){
                        this.archivoLayout.cliente_id = this.cliente_value;
                        this.archivoLayout.empresa_id = this.empresa_value;
                        this.archivoLayout.rol_name   = "admin-empresa";
                    }
                    let formData = new FormData();
                    formData.append("archivo", this.archivoLayout.archivo);
                    formData.append("cliente_id", this.archivoLayout.cliente_id);
                    formData.append("empresa_id", this.archivoLayout.empresa_id);
                    formData.append("tipo", this.archivoLayout.tipo);

                    if (this.rol == "admin-sucursal") {
                        formData.append("sucursal_id", this.archivoLayout.sucursal_id);
                    }

                    formData.append("usuario_id", this.datosLogin.id);
                    
                    Vue.axios({
                        method: "post",
                        url: "tarjetas-salud/import",
                        data: formData,
                        config: { headers: { "Content-Type": "multipart/form-data" } }
                    })
                    .then((response) => {
                        
                        if (response.status == 200) {
                            Notify.Success("Operación exitosa", "Se ha realizado la operación correctamente.");
                        }
                        else if(response.status == 202){
                            this.dialogErrores = true;
                            this.erroresModal  = response.data.errores;
                        }

                        this.cerrarModal();
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        //Notify.ErrorToast("Error al subir archivo.");
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                });
            },
            cerrarModalErrores(){
                this.dialogErrores = false;
                this.erroresModal = [];
            },
            redireccionar(){
                this.$router.push('/dashboard');
            },
        },

        created() {
            this.listar()
        },
    }
</script>


<style scoped>
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }

    #btnModalErrores{
        display: none;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
</style>